import axios from "axios";
import Event from "../broadcast.js";
import qs from "qs"; //处理参数
import store from "../store/index"; // store
import { Loading, Message } from "element-ui";

let loading = null;

//开始加载动画
function openLoading() {
  loading = Loading.service({
    lock: true,
    text: "loading...",
    background: "rgba(0,0,0,.7)",
  });
}

//关闭加载动画
function closeLoading() {
  loading.close();
}

const loadingWhileList = [
  "/login/buildLoginNonce",
  "/project/list",
  "/ido/top",
  "/home/info",
];
//添加请求拦截器
axios.interceptors.request.use(
  (config) => {
    if (loadingWhileList.indexOf(config.url) < 0) {
      //加载动画
      // openLoading();
    }
    try {
      if (sessionStorage.token) {
        // 设置统一的请求header
        config.headers.token = sessionStorage.token; //请求头携带token
      }
    } catch (error) {
      console.log(error);
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

//添加响应拦截器
axios.interceptors.response.use(
  (response) => {
    //逻辑处理
    if (response.data.code == 501) {
      store.commit({
        type: "setaddress",
        val: "",
      });
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("address");
      // 通知到 /newdetails
      Event.$emit("schedule");
    }
    return response;
  },
  (error) => {
    Message.closeAll();
    // closeLoading(); //请求超时清除加载动画
    return Promise.resolve(error.response);
  }
);


axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
axios.defaults.timeout = 20000; //设置请求超时时间

function checkStatus(response) {
  return new Promise((resolve, reject) => {
    if (
      response &&
      (response.status === 200 ||
        response.status === 304 ||
        response.status === 400)
    ) {
      try {
        resolve(response.data);
        if (
          response.config.url != "/ido/top" &&
          response.config.url != "/home/info"
        ) {
          // closeLoading(); //关闭加载动画
        }
      } catch (e) {
        //抛出异常
        console.log(e);
      }
    } else {
      try {
        Message.closeAll();
        Message({
          type: "error",
          showClose: true,
          message: "网络异常，请检查网络连接是否正常！",
        });
      } catch (e) {
        //抛出异常
        console.log(e);
      }
    }
  });
}

export default {
  post(url, params) {
    return axios({
      method: "post",
      url: process.env.VUE_APP_BASE_API + url,
      data: params,
    }).then((response) => {
      return checkStatus(response);
    });
  },
  get(url, params) {
    params = qs.stringify(params);
    return axios({
      method: "get",
      url: process.env.VUE_APP_BASE_API + url,
      params,
    }).then((response) => {
      return checkStatus(response);
    });
  },
};
