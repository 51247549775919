import Vue from "vue";
import VueRouter from "vue-router";
import Index from "../views/index.vue";

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "index",
    component: Index,
    children: [
      {
        path: "/",
        name: "home",
        meta: { title: "首页" },
        component: () => import("@/views/home/index.vue"),
      },
      {
        path: "/search",
        name: "search",
        meta: { title: "搜索" },
        component: () => import("@/views/search/index.vue"),
      },
      {
        path: "/news",
        name: "news",
        meta: { title: "新闻" },
        component: () => import("@/views/news/index.vue"),
      },
      {
        path: "/project",
        name: "project",
        meta: { title: "project" },
        component: () => import("@/views/project/index.vue"),
      },
      {
        path: "/project-details",
        name: "project-details",
        meta: { title: "project-details" },
        component: () => import("@/views/project-details/index.vue"),
      },
      {
        path: "/airdrop",
        name: "airdrop",
        meta: { title: "airdrop" },
        component: () => import("@/views/airdrop/index.vue"),
      },
      {
        path: "/airdrop-details",
        name: "airdrop-details",
        meta: { title: "airdrop-details" },
        component: () => import("@/views/airdrop-details/index.vue"),
      },
      {
        path: "/launchpad",
        name: "launchpad",
        meta: { title: "launchpad" },
        component: () => import("@/views/launchpad/index.vue"),
      },
      {
        path: "/make-a-new",
        name: "make-a-new",
        meta: { title: "make-a-new" },
        component: () => import("@/views/makeanew/index.vue"),
      },
      {
        path: "/newdetails",
        name: "newdetails",
        meta: { title: "newdetails" },
        component: () => import("@/views/a-new-details/index.vue"),
      },
      {
        path: "/application",
        name: "application",
        meta: { title: "application" },
        component: () => import("@/views/application/index.vue"),
      },
      {
        path: "/attention",
        name: "attention",
        meta: { title: "attention" },
        component: () => import("@/views/attention/index.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
