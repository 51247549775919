<template>
  <div>
    <div class="mend" v-if="idotopObj.id">
      <img
        class="mendimg"
        :src="
          idotopObj.logo
            ? idotopObj.logo
            : require('../../../static/images/18.png')
        "
        alt=""
        srcset=""
        @error="imageLoad(idotopObj.logo)"
      />
      <p class="title">{{ idotopObj.name }}</p>
      <div class="schedule">
        <el-progress
          :text-inside="false"
          :stroke-width="10"
          :percentage="
            proportions(
              idotopObj.fundraiseProgressInput,
              idotopObj.fundraiseGoalInput
            )
          "
        ></el-progress>
        <div class="box">
          <p>
            Total raise:{{
              $tool.formatMonenyNumber(idotopObj.fundraiseGoalInput)
            }}
            {{ idotopObj.inputToken }}
          </p>
          <span
            >{{
              proportions(
                idotopObj.fundraiseProgressInput,
                idotopObj.fundraiseGoalInput
              )
            }}%</span
          >
        </div>
      </div>

      <div class="Participate cur" @click="entrance(idotopObj.id)">
        {{ $t("Readmore") }}
      </div>
    </div>
  </div>
</template>

<script>
import { idotop } from "@/api/controller";
export default {
  data() {
    return {
      idotopObj: {},
    };
  },
  created() {
    this.require();
  },

  methods: {
    entrance(id) {
      // 广播到 /newdetails
      this.Event.$emit("new-datals");
      this.$router.push({ path: "/newdetails", query: { id: id } });
    },

    proportions(val, newval) {
      if ((val / newval) * 100 >= 0 && (val / newval) * 100 <= 100) {
        return (val / newval) * 100;
      } else {
        return 0;
      }
    },
    imageLoad(src) {
      var Img = new Image();
      Img.src = src;
      if (Img.fileSize > 0 || (Img.width > 0 && Img.height > 0)) {
      } else {
        this.idotopObj.logo = ""; //显示图片未找到 图片
      }
    },
    async require() {
      let res = await idotop();
      // console.log(res, 'header置顶位')
      if (res.code === 0) {
        this.idotopObj = res.data;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.mend {
  display: flex;
  align-items: center;
  .mendimg {
    width: 27px;
    height: 27px;
    border-radius: 50%;
  }
  .Participate {
    width: 76px;
    height: 26px;
    background: #1edfa6;
    border-radius: 13px;
    font-size: 12px;
    text-align: center;
    line-height: 26px;
  }
  /deep/.schedule {
    min-width: 210px;
    margin-right: 25px;
    .el-progress {
      .el-progress-bar,
      .el-progress-bar__outer {
        min-width: 210px;
        margin-right: 0 !important;
        padding-right: 0;
      }
      .el-progress-bar__outer {
        background: rgba(96, 104, 119, 0.25);
      }
      .el-progress__text {
        display: none;
      }
      .el-progress-bar__inner {
        background: #1edfa6;
      }
    }
    .box {
      font-size: 12px;
      color: #72767e;
      display: flex;
      align-items: center;
      justify-content: space-between;
      line-height: 20px;
      p {
        margin-right: 10px;
      }
    }
  }
  .title {
    font-size: 16px;
    color: #72767e;
    margin-left: 10px;
    margin-right: 24px;
  }
}
</style>
