/**
 * @param {Function} func
 * @param {number} wait
 * @param {boolean} immediate
 * @return {*}
 */
export function debounce(func, wait, immediate) {
  let timeout, args, context, timestamp, result;

  const later = function () {
    // 据上一次触发时间间隔
    const last = +new Date() - timestamp;

    // 上次被包装函数被调用时间间隔 last 小于设定时间间隔 wait
    if (last < wait && last > 0) {
      timeout = setTimeout(later, wait - last);
    } else {
      timeout = null;
      // 如果设定为immediate===true，因为开始边界已经调用过了此处无需调用
      if (!immediate) {
        result = func.apply(context, args);
        if (!timeout) context = args = null;
      }
    }
  };

  return function (...args) {
    context = this;
    timestamp = +new Date();
    const callNow = immediate && !timeout;
    // 如果延时不存在，重新设定延时
    if (!timeout) timeout = setTimeout(later, wait);
    if (callNow) {
      result = func.apply(context, args);
      context = args = null;
    }

    return result;
  };
}

export function formatNumber(num) {
  if (num >= 1e3 && num < 1e4) {
    return (num / 1e3).toFixed(1) + "K";
  } else if (num >= 1e6 && num < 1e9) {
    return (num / 1e6).toFixed(1) + "M";
  } else if (num >= 1e9) {
    return (num / 1e9).toFixed(1) + "B";
  } else {
    return num;
  }
}

export function getCache(key, canJsonParse = false) {
  if (canJsonParse) {
    return JSON.parse(localStorage.getItem(key));
  }
  return localStorage.getItem(key);
}
export function setCache(key, value) {
  localStorage.setItem(key, value);
}

export function openUrl(url, isBlank = true) {
  const win = window.open(url, isBlank ? "_blank" : "");
  win.opener = null;
}
