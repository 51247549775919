<template>
  <div
    class="navigationBar"
    v-if="!isMobile"
    :style="isClose ? 'width: 114px' : 'width: 260px'"
  >
    <!-- logo + 标题 -->
    <!-- <div class="logo-title">
      <img
        class="logo"
        :src="createdDate.logoUrl"
        alt="logo"
        srcset=""
        @click="$router.push('/')"
      />
    </div> -->

    <div class="close-menu">
      <svg-icon
        :iconClass="isClose ? 'menu-open' : 'menu-close'"
        @click="closeMenu"
      />
    </div>

    <div :class="['nav-left', { isClose: isClose }]">
      <div :class="['nav-bar', { active: activeNav == '/' }]">
        <div class="nav-one" @click="navNext('/')">
          <svg-icon
            iconClass="home"
            :className="activeNav == '/' ? 'active-logo--primary' : ''"
          />
          <span>{{ $t("homePapge") }}</span>
        </div>
      </div>

      <div :class="['nav-bar', { active: activeNav == '/project' }]">
        <div class="nav-one" @click="navNext('/project')">
          <svg-icon
            iconClass="project"
            :className="activeNav == '/project' ? 'active-logo--primary' : ''"
          />
          <span>{{ $t("project") }}</span>
        </div>
      </div>
      <div :class="['nav-bar', { active: activeNav == '/airdrop' }]">
        <div class="nav-one" @click="navNext('/airdrop')">
          <svg-icon
            iconClass="airdrop"
            :className="activeNav == '/airdrop' ? 'active-logo--primary' : ''"
          />
          <span>{{ $t("airdrop.name") }}</span>
        </div>
      </div>
      <!-- Launchpad -->
      <div :class="['nav-bar', { active: activeNav == '/launchpad' }]">
        <div class="nav-one" @click="navNext('/launchpad')">
          <svg-icon
            iconClass="launchpad"
            :className="activeNav == '/launchpad' ? 'active-logo--primary' : ''"
          />
          <span>{{ $t("launchpad") }}</span>
        </div>
      </div>
    </div>

    <template v-if="!isClose">
      <div class="submit-airdrop cur" @click="Submitopen">Submit Project</div>

      <div class="share">
        <div
          class="icon twitter"
          v-if="createdDate.twitterUrl"
          @click="location()"
        >
          <svg-icon iconClass="Twitter" className="office-icon"></svg-icon>
        </div>
        <div
          class="icon medium"
          v-if="createdDate.mediumUrl"
          @click="facebook()"
        >
          <svg-icon iconClass="Medium" className="office-icon"></svg-icon>
        </div>
        <div
          class="icon telegram"
          v-if="createdDate.telegramUrl"
          @click="telegram()"
        >
          <svg-icon iconClass="Telegram" className="office-icon"></svg-icon>
        </div>
      </div>
    </template>
  </div>

  <el-drawer
    v-else
    :visible="mobileVisible"
    :with-header="false"
    class="drawer"
    size="50%"
    @close="mobileClick"
  >
    <div class="header" @click="mobileClick">
      <i class="el-icon-s-operation"></i>
    </div>

    <div class="nav-mobile">
      <div :class="['nav-left', { isClose: isClose }]">
        <div :class="['nav-bar', { active: activeNav == '/' }]">
          <div class="nav-one" @click="navNext('/')">
            <svg-icon
              iconClass="home"
              :className="activeNav == '/' ? 'active-logo--primary' : ''"
            />
            <span class="menu-item">{{ $t("homePapge") }}</span>
          </div>
        </div>

        <div :class="['nav-bar', { active: activeNav == '/project' }]">
          <div class="nav-one" @click="navNext('/project')">
            <svg-icon
              iconClass="project"
              :className="activeNav == '/project' ? 'active-logo--primary' : ''"
            />
            <span class="menu-item">{{ $t("project") }}</span>
          </div>
        </div>
        <div :class="['nav-bar', { active: activeNav == '/airdrop' }]">
          <div class="nav-one" @click="navNext('/airdrop')">
            <svg-icon
              iconClass="airdrop"
              :className="activeNav == '/airdrop' ? 'active-logo--primary' : ''"
            />
            <span class="menu-item">{{ $t("airdrop.name") }}</span>
          </div>
        </div>
        <!-- Launchpad -->
        <div :class="['nav-bar', { active: activeNav == '/launchpad' }]">
          <div class="nav-one" @click="navNext('/launchpad')">
            <svg-icon
              iconClass="launchpad"
              :className="
                activeNav == '/launchpad' ? 'active-logo--primary' : ''
              "
            />
            <span class="menu-item">{{ $t("launchpad") }}</span>
          </div>
        </div>
      </div>
      <div class="mobile-subs">
        <div class="submit-airdrop cur" @click="Submitopen">Submit Project</div>

        <div class="share">
          <div
            class="icon twitter"
            v-if="createdDate.twitterUrl"
            @click="location()"
          >
            <svg-icon iconClass="Twitter" className="office-icon"></svg-icon>
          </div>
          <div
            class="icon medium"
            v-if="createdDate.mediumUrl"
            @click="facebook()"
          >
            <svg-icon iconClass="Medium" className="office-icon"></svg-icon>
          </div>
          <div
            class="icon telegram"
            v-if="createdDate.telegramUrl"
            @click="telegram()"
          >
            <svg-icon iconClass="Telegram" className="office-icon"></svg-icon>
          </div>
        </div>
      </div>
    </div>
  </el-drawer>
</template>

<script>
import { homeinfo } from "@/api/require.js";
import { getCache, setCache } from "@/utils";
import { mapGetters } from "vuex";

export default {
  props: {
    mobileVisible: Boolean,
  },
  data() {
    return {
      isClose: getCache("menuClose", true),
      activeNav: 0,
      createdDate: {}, //配置信息
    };
  },
  computed: {
    ...mapGetters(["isMobile"]),
  },
  watch: {
    $route: {
      handler(newval) {
        this.activeNav = newval.path;
        if (newval.path == "/project-details") {
          this.activeNav = "/project";
        } else if (
          newval.path == "/airdrop-details" ||
          newval.path == "/make-a-new"
        ) {
          this.activeNav = "/airdrop";
        } else if (
          newval.path == "/make-a-new" ||
          newval.path == "/application" ||
          newval.path == "/newdetails" ||
          newval.path == "/attention"
        ) {
          this.activeNav = "/launchpad";
        }
        this.mobileClick();
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    this.homeinfo();
  },
  methods: {
    mobileClick() {
      this.$emit("update:mobileVisible", false);
    },
    Submitopen() {
      window.open("https://forms.gle/RuDUWD1ZRug9jkwcA");
    },
    telegram() {
      window.open(this.createdDate.telegramUrl);
    },

    facebook() {
      window.open(this.createdDate.mediumUrl);
    },

    location() {
      window.open(this.createdDate.twitterUrl);
    },

    async homeinfo() {
      let res = await homeinfo();
      if (res.code === 0) {
        document.title = res.data.websiteTitle;
        this.createdDate = res.data;
      }
    },

    navNext(index) {
      this.activeNav = index;
      this.$router.push(index);
    },
    closeMenu() {
      this.isClose = !this.isClose;
      setCache("menuClose", this.isClose);
    },
  },
};
</script>

<style lang="less" scoped>
.navigationBar {
  height: 90vh;
  padding-top: 40px;
  position: relative;
  transition: width 0.4s;

  .submit-airdrop {
    width: 110px;
    height: 30px;
    position: absolute;
    bottom: 60px;
    left: 50%;
    transform: translateX(-50%);
    color: #7a8087;
    font-size: 14px;
    text-align: center;
    line-height: 30px;
    border-radius: 5px;
    &:hover {
      color: #f0f0f0;
    }
  }
  .share {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);

    transition: all 0.25s ease;
    display: flex;
    align-content: center;
    .icon {
      font-size: 26px;
      cursor: pointer;
      width: 24px;
      height: 20px;
      color: #7a8087;
      padding: 0 4px;

      &:not(:first-child) {
        margin-left: 15px;
      }

      &.twitter:hover {
        color: #00aced;
      }
      &.medium:hover {
        color: #f0f0f0;
      }
      &.telegram:hover {
        color: #1296db;
      }
    }
  }
  .nav-left {
    margin-top: 39px;
    .nav-one {
      position: relative;
      cursor: pointer;
      padding: 2px 20px 2px 20px;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      height: 54px;
      color: #7a8087;
      margin-left: 38px;

      span {
        font-size: 14px;
        margin: 0 20px;
        font-family: PingFang SC;
        white-space: nowrap;
      }
      img {
        width: 20px;
        height: 18px;
      }
    }

    &.isClose {
      span {
        display: none;
      }
    }

    .nav-bar {
      position: relative;
      padding-right: 20px;

      &::after {
        content: "";
        border-radius: 5px;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 2px;
        height: 100%;
        z-index: 1;
      }
      &.active::after {
        background-color: #21e2a9;
      }
    }
    .active-logo--primary {
      color: #21e2a9;
    }
    .active .nav-one {
      color: #f0f0ef;
      background: linear-gradient(0, #23252b, #1b1d23);
      border-radius: 10px;
      box-shadow: 0px 4px 10px 1px rgba(21, 23, 28, 0.4);
    }
  }
  .logo-title {
    display: flex;
    align-items: center;
    padding-left: 20px;
    .logo {
      cursor: pointer;
      width: 101px;
      height: 25px;
    }
  }

  .close-menu {
    text-align: left;
    padding-left: 50px;
    font-size: 22px;
    cursor: pointer;
    color: #7a8087;
  }
}
.drawer {
  .header {
    text-align: right;
    padding: 30px 20px;
    font-size: 28px;
  }
  /deep/ .el-drawer {
    background: #22252c;
    color: #7a8087;
  }

  .nav-mobile {
    padding: 20px;

    .nav-one {
      .menu-item {
        font-size: 14px;
      }
    }
    .nav-bar {
      text-align: left;
      line-height: 2;
      padding: 10px 20px;
    }
    & .nav-bar .svg-icon {
      margin: 0 8px;
    }

    & .nav-bar.active {
      color: #fefefe;
      background: linear-gradient(0, #23252b, #1b1d23);
      border-radius: 10px;
      box-shadow: 0px 4px 10px 1px rgba(21, 23, 28, 0.4);

      .svg-icon {
        color: #21e2a9;
      }
    }
  }

  .mobile-subs {
    position: absolute;
    width: 100%;
    bottom: 40px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 14px;

    .share {
      padding: 10px 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      .svg-icon {
        font-size: 24px;
        padding: 0 4px;
      }
    }
  }
}
</style>
