module.exports = {
  empty: "暂无数据",
  total: "总量",
  wallet: "钱包登录",
  launchpad: "打新",
  homePapge: "首页",
  project: "项目",
  news: "新闻",
  english: "English",
  chinese: "简体中文",
  category: "板块分类",
  all: "全部",
  investors: "投资机构",
  amount: "融资总量",
  round: "融资轮次",
  findinview: "请输入英文搜索...",
  AMOUNT: "融资总量",
  shares: "分享",
  website: "官网地址",
  DESCRIPTION: "描述",
  ANNOUNCEMENT: "公告",
  INVESTORS: "投资方",
  OTHER: "其他",
  Statistics: "数据更新于35分钟前",
  vote: "你已投票",
  tomorrow:
    "您的投票结果有效时间为 24 小时。如您想更新对 {project} 的感受，欢迎明天再来！",
  copySuccess: "复制成功",
  copyFail: "复制失败",
  copyNotSupport: "浏览器不支持复制",
  noMore: "没有更多了",
  million: {
    key: {
      start: 0,
      end: 1000000,
    },

    value: "0 - 100万",
  },
  million1: {
    key: {
      start: 1000000,
      end: 5000000,
    },

    value: "100万 - 500万",
  },
  million2: {
    key: {
      start: 5000000,
      end: 50000000,
    },
    value: "500万 - 5000万",
  },
  million3: {
    key: {
      start: 50000000,
      end: 100000000,
    },
    value: "5000万 - 1亿",
  },
  million4: {
    key: {
      start: 100000000,
      end: 1000000000,
    },
    value: "1亿 - 10亿",
  },
  million5: {
    key: {
      start: 1000000000,
      end: 1000000000000,
    },
    value: "10亿以上",
  },
  financing: [
    { key: "Seed", value: "Seed" },
    { key: "SeriesA", value: "Series A" },
    { key: "Steategic", value: "Steategic" },
    { key: "SeriesB", value: "Series B" },
    { key: "PreSeed", value: "Pre-Seed" },
    { key: "ExtendedSeed", value: "Extended Seed" },
    { key: "SeriesC", value: "Series C" },
    { key: "PreSerirsA", value: "Pre-Serirs A" },
    { key: "ExtendedSeriesB", value: "Extended Series B" },
    { key: "EDebtFinancing", value: "EDebt Financing" },
    { key: "Unknown", value: "Unknown" },
  ],
  Updateon: "更新于",
  recharge36: "请先安装MetaMask",
  password3: "确 定",
  error: "操作失败",
  airdrop: {
    name: "空投",
    Project: "项目名称",
    Participated: "当前参与人数",
    NumberofWinners: "空投人数",
    TotalAirdropAmount: "空投总量",
    Endsin: "结束于",
    OngoingAirdrops: "正在进行",
    UpcomingAirdrops: "即将到来",
    FinishedAirdrops: "完成的",
    wicryptcom: "官网地址",
    Community: "官方社区",
    Whitepaper: "白皮书",
    Overview: "项目概况",
    Timeremaining: "倒计时",
    Participate: "立即参与",
    DAYS: "天",
    HOURS: "小时",
    MINS: "分钟",
    Ongoing: "进行中",
    SelfReportedTags: "标签",
    Participants: "参与人数",
    Share: "分享",
    CheckOtherAirdrops: "查看更多空投项目",
    Upcoming: "即将到来",
    participated: "你已经参加了这次空投",
    participate: "怎样参与？",
    Starting: "即将开始",
  },
  givealike: {
    title: "您今天对 {project} 有什么看法？",
    sontitle: "投票查看社区结果",
  },
  market: "我们正在准备销售",
  catalogue: "目录",
  cataloguelist: [
    { value: "关于打新", key: "aboutLaunchpad" },
    { value: "项目概况", key: "overview" },
    { value: "上市策略", key: "marketStrategy" },
    { value: "产品可行性", key: "productViability" },
    { value: "产品路线图", key: "productRoadmap" },
    { value: "收入流", key: "revenueStreams" },
    { value: "团队", key: "team" },
    { value: "代币制", key: "tokenEconomy" },
    { value: "令牌指标详情", key: "tokenMetricsDetailed" },
    { value: "合作伙伴", key: "partners" },
  ],

  blacklist: "加入黑名单",
  blacklisttitle:
    "我们很高兴能在Fomoin上发布IDO。 为了有机会赢得白名单，请填写以下表格并执行相应的任务。 如果您有任何问题，请联系我们!  ",
  essential: "基本信息",
  fullname: "全名*",
  mailbox: "邮箱地址*",
  SocialMedia: "社交媒体",
  SocialMediatitle:
    "请完成下面的社会任务。它们是可选的，但增加了被列入白名单的机会。",
  country: "国家..*",
  Conditions: "您是否同意",
  Conditions2: "上述条款及细则?",
  Readmore: "查看更多",

  airdropForms: {
    desc1: "Add The Sandbox to Watchlist dada Watchlist",
    desc2: "Please add to watchlist first!",
    follow: "关注",
    tips: "请完成以上任务，我们将从关注者中抽取名单。",
    WalletAddress: "钱包地址",
    pleaseFill: "请输入你的",
    handle: "用户名",
    WalletAddressPlaceholder: "请输入你的钱包地址...",
    AddressRequired: "地址必填",
    agreeError: "请同意协议!",
    JoinAirdrop: "加入空投",
    agree1:
      "我已理解并承认满足上述条件并不能保证中奖，因为获奖者将完全由空投的合作伙伴根据他们的选择标准决定。",
    agree2: "如果我对中奖结果或分发有任何疑问，我将直接联系空投的合作伙伴。",
    agree3:
      "我已经审查网络安全实践，并将采取必要的预防措施来保护自己免受恶意行为者的侵害。",
  },
};
