import Ajax from './axios'

// banner图列表
export async function homeBanner() {
	return await Ajax.get("/home/banner")
}

// 首页项目列表
export async function projects(size,category) {
	if(size && category){
		return await Ajax.get("/home/projects/" + size + '/' + category)
	}else{
		return await Ajax.get("/home/projects/" + size)
	}
	
}

export async function buildLoginCode(address) {
	return await Ajax.post("/login/buildLoginNonce/" + address);
}

export async function login(address,code,sig) {
	return await Ajax.post("/login",{
		address : address,
		sig : sig,
		nonce : code
	});
}

// 获取类型列表
export async function categorys() {
	return await Ajax.get("/info/categorys")
}
// 获取投资机构列表
export async function investors() {
	return await Ajax.get("/info/investors")
}
// 配置信息
export async function homeinfo() {
	return await Ajax.get("/home/info")
}
// 项目列表
export async function projectlist(data) {
	return await Ajax.post("/project/list",data)
}
// 空投列表
export async function airdroplist(data) {
	return await Ajax.post("/airdrop/list",data)
}
// 空投banner
export async function airdroptop(data) {
	return await Ajax.post("/airdrop/top",data)
}
// 关注/取关
export async function airdropfollow(data) {
	return await Ajax.post("/airdrop/follow",data)
}
// 用户是否参关注项目
export async function isFollow(data) {
	return await Ajax.post("/airdrop/isFollow/" + data)
}
// 参与空投
export async function airdropsubmit(data) {
	return await Ajax.post("/airdrop/submit", data)
}
// 用户是否参与空投
export async function isParticipated(data) {
	return await Ajax.post("/airdrop/isParticipated/" + data)
}
// 获取项目详情
export async function projectdetails(data) {
	return await Ajax.get("/project/" + data)
}
// 空投详情
export async function airdropinfo(id) {
	return await Ajax.get("/airdrop/info/" + id)
}
// 进入参与空投
export async function airdropparticipate(id) {
	return await Ajax.get("/airdrop/participate/" + id)
}
// 获取项目投票数据
export async function projectvote(id) {
	return await Ajax.get("/project/vote/" + id)
}
// 给项目投票bad
export async function projectbad(id) {
	return await Ajax.get("/project/vote/" + id + '/bad')
}
// 给项目投票good
export async function projectgood(id) {
	return await Ajax.get("/project/vote/" + id + '/good')
}

// 查看是否可以投票
export async function projectcanVote(id) {
	return await Ajax.get("/project/canVote/" + id)
}

// 新闻列表
export async function newslist(headline,pageIndex,pageSize) {
	return await Ajax.get("/news/list?headline=" + headline + "&pageIndex=" + pageIndex + '&pageSize=' + pageSize)
}
