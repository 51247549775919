<template>
  <div class="index">
    <el-container class="el-container-one">
      <el-aside width="auto">
        <!-- 右侧导航+logo -->
        <navigation-bar :mobileVisible.sync="mobileVisible"></navigation-bar>
      </el-aside>
      <el-container>
        <el-header>
          <!-- 搜索栏 -->
          <Header :mobileVisible.sync="mobileVisible"></Header>
        </el-header>
        <el-main :class="[falg ? '' : 'butterfly']">
          <el-dialog
            title=""
            :visible.sync="dialogTableVisible"
            class="login-el"
          >
            <div class="login-title-box">
              <p class="box1">You need an Ethereum wallet to use Fomoin.</p>
              <p class="box2">Connect with one of our available</p>
              <p class="box2">wallet infoproviders or create a new one.</p>
            </div>
            <div class="login-logo" @click="disembark()">
              <img src="@/static/images/login-logo.png" alt="" />
            </div>
            <p class="box3">This is the most popular</p>
          </el-dialog>

          <!-- 出口 -->
          <router-view />
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import NavigationBar from "@/components/navigationBar";
import Header from "@/components/header/header";

import { mapGetters } from "vuex";

export default {
  name: "index",
  components: {
    NavigationBar,
    Header,
  },
  data() {
    return {
      dialogTite: "",
      dialogNodata: false,
      mobileVisible: false,
      dialogTableVisible: false,
      falg: true,
    };
  },
  computed: {
    ...mapGetters(["isMobile"]),
  },
  watch: {
    $route: {
      handler(newval) {
        if (newval.path == "/newdetails") {
          this.falg = false;
        } else {
          this.falg = true;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    this.Event.$on("landing", (res) => {
      // 收到project-details来的广播
      this.dialogTableVisible = true;
    });

    this.Event.$on("data-close", (res) => {
      // 接收 project-details 广播
      this.dialogTableVisible = false;
    });
  },
  methods: {
    disembark() {
      // 发广播到header
      this.Event.$emit("data-landing");

      setTimeout(() => {
        this.dialogTableVisible = false;
      }, 500);
    },
  },
};
</script>

<style lang="less" scoped>
.index {
  height: 100vh;
  .el-container-one {
    height: 100%;
  }
}
.el-header {
  background-color: #22252c;
  color: #333;
  text-align: center;
  line-height: 60px;
  padding: 20px;
  height: auto !important;
}

.el-aside {
  background-color: #22252c;
  color: #333;
  text-align: center;
}

.el-main {
  background-color: #22252c;
  color: #7a8087;
  padding: 20px;
  overflow: auto;
  /deep/.login-el {
    display: flex;
    align-items: center;
    .el-dialog {
      width: 771px;
      height: 300px;
      background: #ffffff;
      border-radius: 30px;
      margin: 0 auto !important;
      position: relative;
      .el-dialog__header {
        .el-dialog__headerbtn {
          z-index: 9999;
        }
      }
      .el-icon-close:before {
        border: 1px solid #666;
        border-radius: 50%;
      }
      .el-dialog__body {
        position: relative;
        top: -40px;
      }
      .box3 {
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #9f9f9f;
        text-align: center;
        margin-top: 20px;
        margin-bottom: 30px;
      }
      .login-logo {
        cursor: pointer;
        box-sizing: border-box;
        margin: 47px auto 0;
        width: 450px;
        height: 93px;
        background: #f9f9f9;
        border: 1px solid #20cda2;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 284px;
          height: 52px;
        }
      }
      .login-title-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        z-index: -9999;
        p {
          line-height: 25px;
        }
        .box1 {
          font-size: 20px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #2a2a2a;
        }
        .box2 {
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #9f9f9f;
        }
      }
    }
  }
}

.butterfly {
  padding: 0 5% 20px;
}

body > .el-container {
  margin-bottom: 40px;
}

.el-container:nth-child(5) .el-aside,
.el-container:nth-child(6) .el-aside {
  line-height: 260px;
}

.el-container:nth-child(7) .el-aside {
  line-height: 320px;
}

.isMobile {
  .el-main .login-el /deep/.el-dialog {
    width: calc(100% - 40px );
    height: 380px;

    .el-dialog__body {
      top: 0;
    }
    .login-logo {
      width: 100%;
      height: auto;
      padding: 20px 10px;
    }
    .login-logo img {
      width: 100%;
      height: auto;
    }

    .box1 {
      font-size: 18px;
    }
  }
}
</style>
