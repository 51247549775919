<template>
  <div class="header" v-if="!isMobile">
    <div class="logo">
      <img
        src="https://oss.fomoin.finance/482e6d28-ee52-4cb1-b040-902793b9548f.svg"
        class="img"
      />
    </div>
    <!-- <mend></mend> -->
    <div style="display: flex; align-items: center">
      <el-dropdown
        v-if="Forte"
        @command="investorsFuntion"
        :class="['metamask', { active: Forte }]"
      >
        <span class="el-dropdown-link cur">
          <img
            class="identify"
            src="../../static/images/makeanew/metamask-fox.svg"
            alt=""
            srcset=""
          />
          <span class="metamask-text">METAMASK</span>
        </span>
        <el-dropdown-menu slot="dropdown" class="pull-down">
          <el-dropdown-item>
            <div class="item-box">
              <div class="heder">
                <img
                  class="identify"
                  style="width: 20px; height: 20px"
                  src="../../static/images/makeanew/metamask-fox.svg"
                  alt=""
                  srcset=""
                />
              </div>
              <div class="caption">
                <p
                  class=""
                  style="font-size: 14px; font-weight: bold; color: #ffffff"
                >
                  MetaMask
                </p>
                <p style="font-size: 12px; color: #7b8087">
                  {{ identify($store.state.address) }}
                </p>
              </div>
              <img
                style="
                  width: 14px;
                  height: 14px;
                  margin-left: 12px;
                  position: relative;
                  top: 10px;
                "
                src="../../static/images/makeanew/fuz.png"
                alt=""
                @click="copy($store.state.address)"
                srcset=""
              />
            </div>
          </el-dropdown-item>
          <el-dropdown-item command="attention">
            <div class="Watchlist">
              My Watchlist
              <i class="el-icon-arrow-right"></i>
            </div>
          </el-dropdown-item>
          <el-dropdown-item command="Make-a-new">
            <div class="Watchlist">
              Participating launchpad
              <i class="el-icon-arrow-right"></i>
            </div>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <div v-else class="metamask" @click="Globaland">
        <span class="el-dropdown-link cur">
          <img
            class="identify"
            src="../../static/images/makeanew/metamask-fox.svg"
            alt=""
            srcset=""
          />
          <span class="metamask-text">METAMASK</span>
        </span>
      </div>

      <div class="el-input__inner-input1 cur">
        <el-input
          v-model="searchValue"
          class="search-input"
          @change="onSearch"
          :placeholder="$t('findinview')"
        ></el-input>
        <img
          @click="onSearch"
          src="../../static/images/makeanew/sousuotianchong.png"
        />
      </div>
      <el-select v-model="switchover" @change="philology">
        <el-option :label="$t('english')" value="en"></el-option>
        <el-option :label="$t('chinese')" value="zh"></el-option>
      </el-select>
    </div>
  </div>

  <div class="header mobile-header" v-else>
    <div class="logo">
      <img
        src="https://oss.fomoin.finance/482e6d28-ee52-4cb1-b040-902793b9548f.svg"
        class="img"
      />
    </div>
    <div class="taggle" @click="mobileClick">
      <i class="el-icon-s-operation"></i>
    </div>
  </div>
</template>

<script>
var ethUtil = require("ethereumjs-util");
var sigUtil = require("eth-sig-util");
import { buildLoginCode, login } from "@/api/require.js";
import mend from "./component/mend.vue";
import { mapGetters } from "vuex";
export default {
  props: {
    mobileVisible: Boolean,
  },
  components: {
    mend,
  },
  data() {
    return {
      switchover: localStorage.getItem("locale") || "en",
      input1: "",
      Forte: true,
      devtool: true,
      libsate: true,
      searchValue: "",
    };
  },
  computed: {
    ...mapGetters(["isMobile"]),
  },
  async created() {
    let _this = this;
    // 监听账号变化
    try {
      ethereum.on("accountsChanged", function (accounts) {
        if (accounts[0] == undefined) {
          _this.$store.commit({
            type: "setaddress",
            val: "",
          });
          sessionStorage.removeItem("token");
          sessionStorage.removeItem("address");
          // 退出账号
          _this.Event.$emit("data-refresh", true);
          _this.Forte = false;
        } else if (accounts[0]) {
          if (_this.devtool) {
            _this.getAccount();
            _this.devtool = false;
          }
          setTimeout(() => {
            _this.devtool = true;
          }, 4000);
        }
      });
    } catch (error) {
      console.log(error);
    }

    this.philology();

    this.Event.$on("data-landing", (res) => {
      // 收到index 的广播
      this.getAccount();
    });

    try {
      this.metamask = window.ethereum;
      if (this.metamask) {
        this.hasMetaMask = true;
        this.address = this.metamask.selectedAddress;
        this.$store.commit({
          type: "setaddress",
          val: this.address,
        });
      } else {
        this.hasMetaMask = false;
      }
    } catch (error) {}
  },
  methods: {
    mobileClick() {
      this.$emit("update:mobileVisible", true);
    },
    onSearch(e) {
      console.log(e);
      this.$router.push("/search?word=" + this.searchValue);
    },
    async copy(val) {
      let target = document.createElement("input"); //创建input节点
      target.value = val; // 给input的value赋值
      document.body.appendChild(target); // 向页面插入input节点
      target.select(); // 选中input
      try {
        await document.execCommand("Copy"); // 执行浏览器复制命令
        this.$message({
          message: this.$t("copySuccess"),
          type: "success",
        });
      } catch {
        this.$message({
          message: this.$t("copyNotSupport"),
          type: "error",
        });
      }
    },

    investorsFuntion(command) {
      if (command == "attention") {
        this.$router.push("/make-a-new");
      } else if (command == "Make-a-new") {
        this.$router.push("/attention");
      }
    },

    identify(address) {
      if (address) {
        try {
          if (!sessionStorage.getItem("token")) {
            this.$store.commit({
              type: "setaddress",
              val: "",
            });
            this.Forte = false;
          } else {
            let str =
              address.substring(0, 10) + "......" + address.substring(37, 42);
            this.Forte = true;
            return str;
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        try {
          if (sessionStorage.getItem("token")) {
            this.$store.commit({
              type: "setaddress",
              val: sessionStorage.getItem("address"),
            });
            let str =
              address.substring(0, 10) + "......" + address.substring(37, 42);
            this.Forte = true;
            return str;
          } else {
            this.Forte = false;
          }
        } catch (error) {
          console.log(error);
        }
      }
    },

    Globaland() {
      try {
        if (!sessionStorage.getItem("token")) {
          console.log("广播到index.vue");
          // 广播到index.vue
          this.Event.$emit("landing");
          return false;
        } else {
          return true;
        }
      } catch (error) {
        console.log(error);
      }
    },

    async buildLoginCode() {
      if (this.libsate) {
        this.libsate = false;
        const res = await buildLoginCode(this.address);
        console.log(res);
        if (res.code !== 0) {
          alert(res.msg);
          throw new Error();
        }

        setTimeout(() => {
          this.libsate = true;
        }, 2000);
        return res.data;
      }
    },
    async getAccount() {
      let _this = this;
      if (!this.hasMetaMask) {
        this.$alert(this.$t("recharge36"), "", {
          confirmButtonText: this.$t("password3"),
          callback: () => {
            window.open("https://metamask.io/");
          },
        });
      } else {
        this.initialAccount = true;
        try {
          await this.metamask.request({ method: "eth_requestAccounts" });
          const address = (this.address = this.metamask.selectedAddress);
          console.log(this.address);
          this.connectError = "";
          this.initialAccount = false;
          const code = await this.buildLoginCode();
          var msg = ethUtil.bufferToHex(new Buffer(code, "utf8"));
          var from = this.address;
          var params = [msg, from];
          var method = "personal_sign";
          web3.currentProvider.sendAsync(
            {
              method,
              params,
              from,
            },
            async function (err, result) {
              if (err) return console.error(err);
              if (result.error) return console.error(result.error);
              // console.log('PERSONAL SIGNED:' + JSON.stringify(result.result))
              console.log(address);
              console.log(code);
              console.log(result.result);
              const { data: token } = await login(address, code, result.result);
              console.log(token);
              console.log(address);
              console.log("token:" + JSON.stringify({ token, address }));
              sessionStorage.setItem("token", token);
              sessionStorage.setItem("address", address);
              _this.Forte = true;
              _this.$store.commit({
                type: "setaddress",
                val: address,
              });
              // 广播到project-details 或者 airdrop-details  newdetails
              _this.Event.$emit("data-refresh");
              // 广播到 index 关闭登陆框
              _this.Event.$emit("data-close");
            }
          );
        } catch (e) {
          this.connectError = this.$t("recharge.recharge34");
          console.error("连接metamask失败" + e);
        }
      }
    },
    // 语言切换
    philology() {
      let lang = this.switchover === "zh" ? "zh" : "en";

      this.$i18n.locale = lang;
      localStorage.setItem("locale", lang);
    },
  },
};
</script>

<style lang="less" scoped>
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .logo {
    height: 100%;
    width: 140px;
    .img {
      vertical-align: middle;
      object-fit: contain;
    }
  }
  .metamask {
    position: relative;
    margin: 0 20px;
    .el-dropdown-link {
      color: #f0f0ef;
      // width: 120px;
      height: 33px;
      background: linear-gradient(0deg, #2d3138, #3a3f45);
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 20px;
      padding: 2px 20px;
    }
    .identify {
      width: 25px;
      height: 25px;
      border-radius: 50%;
      font-size: 14px;
    }

    &-text {
      margin-left: 10px;
      letter-spacing: 2px;
    }

    &:before {
      position: absolute;
      content: "";
      width: 8px;
      height: 8px;
      right: 2px;
      border-radius: 50%;
      top: 2px;
      background: #7b8087;
    }

    &.active::before {
      background: #1edfa6;
    }
  }
  .wallet {
    width: 70px;
    border-radius: 5px;
    height: 26px;
    padding: 0 10px;
    text-align: center;
    line-height: 26px;
    background: #41ffc7;
    color: #1f2258;
    font-size: 14px;
    cursor: pointer;
  }
  .el-input__inner-input1 {
    width: 200px;
    height: 35px;
    background: linear-gradient(0deg, #2d3138, #3a3f45);
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 20px;
    padding: 0 20px;

    &:focus-visible {
      outline: none;
    }
    /deep/.search-input {
      .el-input__inner {
        background-color: unset;
        height: initial;
        border: none;
        color: #f0f0ef;

        &::-webkit-input-placeholder {
          color: #7b8087;
        }
        &:-moz-placeholder {
          color: #7b8087;
        }
        &::-moz-placeholder {
          color: #7b8087;
        }
        &:-ms-input-placeholder {
          color: #7b8087;
        }
      }
    }
    img {
      width: 15px;
      height: 15px;
    }
  }
  /deep/.el-select {
    margin: 0 20px;
    padding: 0 20px;
    .el-input__inner {
      height: 35px;
      line-height: 35px;
      border: none;
      width: 180px;
      color: #909399;
      background: linear-gradient(0deg, #2d3138, #3a3f45);
      border-radius: 20px;
      padding: 0 20px;
    }
  }
}
.mobile-header {
  .taggle {
    font-size: 28px;
    color: #909399;
  }
}
</style>
