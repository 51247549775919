<template>
  <div id="app" :class="{ isMobile: isMobile }">
    <router-view />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      isMobile: false,
    };
  },
  mounted() {
    const devicesScreenWidth = window.screen.width;
    this.isMobile = devicesScreenWidth <= 872;

    this.$store.commit({
      type: "setIsMobile",
      val: devicesScreenWidth <= 872,
    });
  },
};
</script>
<style>
.box-wx-menu .box-list {
  max-height: 600px !important;
  overflow-y: auto !important;
  scrollbar-color: #26e7ae #fff;
  scrollbar-width: thin;
}
.box-wx-menu .box-list::-webkit-scrollbar {
  width: 3px;
  background: #fff;
  border-radius: 20px;
}
.box-wx-menu .box-list::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #26e7ae;
}
.box-wx-menu .box-list::-o-scrollbar {
  width: 3px;
  background: #fff;
  border-radius: 20px;
}
.box-wx-menu .box-list::-o-scrollbar-thumb {
  border-radius: 10px;
  background: #26e7ae;
}
.box-wx-menu .box-list::-ms-scrollbar {
  width: 3px;
  background: #fff;
  border-radius: 20px;
}
.box-wx-menu .box-list::-ms-scrollbar-thumb {
  border-radius: 10px;
  background: #26e7ae;
}

.el-dropdown-menu-box {
  width: 124px;
  background: #203861 !important;
  border: none !important;
  padding: 5px 0 !important;
}
.el-dropdown-menu-box .el-dropdown-menu__item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #1edfa6;
  line-height: 24px;
}
.el-dropdown-menu-box .el-dropdown-menu__item p {
  width: 110px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.el-dropdown-menu-box .el-dropdown-menu__item:not(:first-child) {
  border-top: 1px solid #224c6a;
}
.el-dropdown-menu-box .el-dropdown-menu__item img {
  margin-left: 5px;
}
.el-dropdown-menu-box .popper__arrow {
  border-bottom-color: #203861 !important;
}
.el-dropdown-menu-box .popper__arrow::after {
  border-bottom-color: #203861 !important;
}

.hide {
  display: none;
}
.show {
  display: block;
}

.show-flex {
  display: flex;
}
</style>
