import "element-ui/lib/theme-chalk/index.css";
import "swiper/swiper.min.css";
import "@/assets/css/search.css";
import "./assets/css/reset.css";

import './app.less';

import Vue from "vue";
import App from "./App.vue";
import router from "./router";

import "./icons"; // icon
import store from "./store";

import tool from "./static/tool";
Vue.prototype.$tool = tool;

import VueAwesomeSwiper from "vue-awesome-swiper";
import SwiperCore, { Autoplay, Navigation } from "swiper";
SwiperCore.use([Navigation, Autoplay]);

Vue.use(VueAwesomeSwiper);

import ElementUI from "element-ui";
Vue.use(ElementUI);

import VueClipboard from "vue-clipboard2";

Vue.use(VueClipboard);

import broadcast from "./broadcast";
Vue.prototype.Event = broadcast;

import VueI18n from "vue-i18n";
Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: localStorage.getItem('locale') || "zh", // 语言标识
  messages: {
    zh: require("./vueL18n/zh"),
    en: require("./vueL18n/en"),
  },
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
