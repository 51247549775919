/**
 * @author fuyunchun
 * @description 将中文时间转为英文
 * @param { String } date 需要转换的时间
 * @returns { String } 转换后的时间
 */
function conversion(date = '2021-03-24', falg) {
    try {

        let str = date
        str = str.split(' ')[1]
        str = str.substring(0,5)

        let nume = str
        nume = Number(nume.substring(0,2))
        let steam = 'PM'
        if(nume > 12){
            steam = 'PM'
        }else{
            steam = 'AM'
        }

        var dt = new Date(date);
        if(falg == 2){
            var m = new Array("January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December");
        }else if(falg == 3){
            var m = new Array("1月", "2月", "3月", "4月", "5月", "6月", "7月", "8月", "9月", "10月", "11月", "12月");
        }else if(falg == 4 || falg == 6){
            var m = new Array("Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Spt", "Oct", "Nov", "Dec");
        }else if(falg == 5){
            var m = new Array("January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December");
        }else{
            var m = new Array("Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Spt", "Oct", "Nov", "Dec");
        }
        var w = new Array("Monday", "Tuseday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday");
        var d = new Array("st", "nd", "rd", "th");
        let mn = dt.getMonth();
        let wn = dt.getDay();
        let dn = dt.getDate();
        var dns;
        if (((dn % 10) < 1) || ((dn % 10) > 3)) {
            dns = d[3];
        }
        else {
            dns = d[(dn % 10) - 1];
            if ((dn == 11) || (dn == 12)) {
                dns = d[3];
            }
        }


        if(falg == 1){
            return m[mn] + " " + dn + "," + dt.getFullYear()
        }else if(falg == 2){
            return dn + " " + m[mn]
        }else if(falg == 3){
            return m[mn] + dn
        }else if(falg == 4){
            return m[mn] + " " + dn
        }else if(falg == 5){
            // January 4th 2022,09:00 AM
            return m[mn] + " " + dn + "th " + dt.getFullYear() + ', '+ str + ' ' + steam
        }else if(falg == 7){
            // January 4th 2022,09:00
            return m[mn] + " " + dn + "th " + dt.getFullYear() + ', '+ str
        }else if(falg == 6){
            // 01 Feb at 2 PM
            return dn + " " + m[mn] + " at " + str
        }else{
            return m[mn] + " " + dn + dns + " " + w[wn - 1] + " " + dt.getFullYear()
            
        }
    } catch (error) {
        console.log(error)
    }
}

/**
 * @author fuyunchun
 * @description 将数字格式 转化成金额格式，三个数字一个逗号格式
 * @param { number } date 需要转换的钱钱
 * @returns { number } 转换后的时间
 */

function formatMonenyNumber(number = 0){
    try {
        let str = []
        if(typeof number === "number"){
            number = String(number)
        }
        if(number.length <= 3){
            return number
        }
        let stout = number
        if(number.indexOf('.') > -1){
            stout = number.split('.')[0]
            String(stout).split('').reverse().forEach((item ,index) => {
                if(index != 0 && index % 3 == 0){
                    str.push(',',item)
                }else{
                    str.push(item)
                }
            })
            return str.reverse().join('') + '.' + number.split('.')[1]
        }else{
            String(stout).split('').reverse().forEach((item ,index) => {
                if(index != 0 && index % 3 == 0){
                    str.push(',',item)
                }else{
                    str.push(item)
                }
            })
            return str.reverse().join('')
        }

    } catch (error) {
        console.info(error)
    }
}

function formatNumber(num, falg) {
    if(falg){
        return  (num / 1e3).toFixed(0) + 'K'
    }else{
        return  (num / 1e3).toFixed(2) + 'K' 
    }
}

function succTime(value) {
    //获取当前时间
    var date = new Date();
    var now = date.getTime();
    //设置截止时间
    //在pc端浏览器可以这样写
    var endDate = new Date(value);
    //移动端必须这样写，因为ios不支持日期中间是-链接，会报错
    //var endDate = new Date("2019/7/22 00:00:00");
    var end = endDate.getTime();
    //时间差
    var differTime = end - now;
    //定义变量,h,m,s保存倒计时的时间
    var h, m, s;
    if (differTime >= 0) {
        h = Math.floor(differTime / 1000 / 60 / 60);
        m = Math.floor(differTime / 1000 / 60 % 60);
        s = Math.floor(differTime / 1000 % 60);
        // h = h < 10 ? ("0" + h) : h;
        // m = m < 10 ? ("0" + m) : m;
        // s = s < 10 ? ("0" + s) : s;
        //递归调用函数所以是延时器不是定时器
        setTimeout( () => {
            succTime(value)
        }, 2000);
        return h + "h " + m + "m";
    } else {
        return 0 + "h" + 0 + "m";
    }
}

function timestampToTime(timestamp, falsg = false) {
    var date = new Date(timestamp);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
    var Y = date.getFullYear() + '-';
    var M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '-';
    var D = (date.getDate() < 10 ? '0'+date.getDate() : date.getDate()) + ' ';
    var h = (date.getHours() < 10 ? '0'+date.getHours() : date.getHours()) + ':';
    var m = (date.getMinutes() < 10 ? '0'+date.getMinutes() : date.getMinutes());
    var s = (date.getSeconds() < 10 ? '0'+date.getSeconds() : date.getSeconds());
    
    let strDate
    if(falsg){
        strDate = Y+M+D+h+m+ ':' + s
    }else{
        strDate = Y+M+D+h+m
    }
    return strDate;
  }

export default {
    conversion,
    formatMonenyNumber,
    formatNumber,
    succTime,
    timestampToTime,
    FileList:[
        // bsc测试网
        {
            chainId: '97',
            rpcUrls:["https://data-seed-prebsc-1-s1.binance.org:8545"],
            nativeCurrency: {
                "name": "tBNB",
                "symbol": "tBNB",
                "decimals": 18
            },
            blockExplorerUrls: ["https://testnet.bscscan.com"]
        },
        // bsc主网
        {
            chainId: '56',
            rpcUrls:["https://bsc-dataseed1.binance.org"],
            nativeCurrency: {
                "name": "BNB",
                "symbol": "BNB",
                "decimals": 18
            },
            blockExplorerUrls: ["https://bscscan.com"]
        },
        // 火币测试网
        {
            chainId: '256',
            rpcUrls:["https://http-testnet.hecochain.com"],
            nativeCurrency: {
                "name": "htt",
                "symbol": "htt",
                "decimals": 18
            },
            blockExplorerUrls: ["https://testnet.hecoinfo.com"]
        },
        // 火币主网
        {
            chainId: '128',
            rpcUrls:["https://http-mainnet.hecochain.com"],
            nativeCurrency: {
                "name": "HT",
                "symbol": "HT",
                "decimals": 18
            },
            blockExplorerUrls: ["https://hecoinfo.com"]
        },
        // 以太坊主网
        {
            chainId: '1',
            rpcUrls:["https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161"],
            nativeCurrency: {
                "name": "ETH",
                "symbol": "ETH",
                "decimals": 18
            },
            blockExplorerUrls: ["https://etherscan.io"]
        },
        // 以太坊主网测试
        {
            chainId: '3',
            rpcUrls:["https://ropsten.infura.io/v3/050f655b6b15437091b1ee29efbccb5e"],
            nativeCurrency: {
                "name": "ETH",
                "symbol": "ETH",
                "decimals": 18
            },
            blockExplorerUrls: ["https://ropsten.etherscan.io"]
        }
    ]
}