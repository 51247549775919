import Ajax from './axios'

// 进行中的IDO项目
export async function idounderway() {
	return await Ajax.post("/ido/underway")
}
// 待选国家
export async function idocountries() {
	return await Ajax.get("/ido/countries")
}

// 获取社交网站列表
export async function getSocialWebsiteLogo(site) {
	return await Ajax.get("/info/getSocialWebsiteLogo?site=" + site)
}

// 即将到来的IDO项目
export async function idoupcoming() {
	return await Ajax.post("/ido/upcoming" );
}
// 顶部扩展位
export async function idotop() {
	return await Ajax.post("/ido/top" );
}
// IDO项目详情 idoinfo
export async function idoinfo(id) {
	return await Ajax.post("/ido/info/" + id);
}
// IDO项目简要信息 
export async function infosimple(id) {
	return await Ajax.post("/ido/info/simple/" + id);
}
// 我参与的IDO项目
export async function idomine(data) {
	return await Ajax.post("/ido/mine", data);
}
// 用户是否参已报名IDO
export async function idoisParticipated(id) {
	return await Ajax.post("/ido/isParticipated/" + id);
}
// 进入参与(白名单)界面
export async function idoparticipate(id) {
	return await Ajax.post("/ido/participate/" + id);
}
// 提交(白名单)申请
export async function idosubmit(data) {
	return await Ajax.post("/ido/submit",data);
}
// 我关注的空投项目
export async function airdropfollows() {
	return await Ajax.get("/airdrop/follows");
}
// 用户中奖状态
export async function idoisWinner(idoId) {
	return await Ajax.get("/ido/isWinner/" + idoId);
}
// 获取时间
export async function sysnow() {
	return await Ajax.get("/home/sys/now");
}

// 查询剩余领取
export async function canExtractAmount(idoId) {
	return await Ajax.get("/ido/canExtractAmount/" + idoId);
}
// 用户协议
export async function idoprotocol() {
	return await Ajax.get("/ido/protocol");
}
// 聚合搜索
export async function homesearch(keywords) {
	return await Ajax.get("/home/search/" + keywords);
}
// 获取指定链的最新高度

export async function chainNetHeight(chainId) {
	return await Ajax.get("/info/chainNetHeight/" + chainId);
}