import Vue from "vue";
import Vuex from "vuex";
import getters from "./getters";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isMobile: false,
    address: "",
    ongoing: "",
  },
  mutations: {
    setaddress(state, val) {
      state.address = val.val;
    },

    setsetaddress(state, val) {
      state.ongoing = val.val;
    },

    setIsMobile(state, val) {
      state.isMobile = val.val;
    },
  },
  getters,
  actions: {},
  modules: {},
});
