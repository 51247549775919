module.exports = {
  empty: "empty",
  total: "Total",
  launchpad: "Launchpad",
  wallet: "Wallet",
  homePapge: "Home page",
  project: "Project",
  news: "News",
  english: "English",
  chinese: "简体中文",
  category: "Category",
  all: "All",
  investors: "INVESTORS",
  amount: "Amount",
  round: "Round",
  findinview: "Find in view...",
  AMOUNT: "AMOUNT",
  shares: "Share",
  website: "WEBSITE",
  DESCRIPTION: "Description",
  ANNOUNCEMENT: "Announcement",
  INVESTORS: "Investors",
  OTHER: "Other",
  Statistics: "Statistics were updated 35 minutes ago",
  vote: "You’ve voted",
  tomorrow:
    "Your vote is for 24 hours.In order to update how you feel about {project},come back tomorrow!",
  copySuccess: "copy success",
  coptFail: "copy fail",
  copyNotSupport: "Your browser does not support copying.",
  noMore: "No More",
  million: {
    key: {
      start: 0,
      end: 1000000,
    },
    value: "0 - $1 million",
  },
  million1: {
    key: {
      start: 1000000,
      end: 5000000,
    },
    value: "1 million - 5 million",
  },
  million2: {
    key: {
      start: 5000000,
      end: 50000000,
    },

    value: "5 million - 50 million",
  },
  million3: {
    key: {
      start: 50000000,
      end: 100000000,
    },

    value: "50 million - 100 million",
  },
  million4: {
    key: {
      start: 100000000,
      end: 1000000000,
    },

    value: "100 million - 1000 million",
  },
  million5: {
    key: {
      start: 1000000000,
      end: 1000000000000,
    },

    value: "1000 million - Infinity",
  },
  financing: [
    { key: "Seed", value: "Seed" },
    { key: "SeriesA", value: "Series A" },
    { key: "Steategic", value: "Steategic" },
    { key: "SeriesB", value: "Series B" },
    { key: "PreSeed", value: "Pre-Seed" },
    { key: "ExtendedSeed", value: "Extended Seed" },
    { key: "SeriesC", value: "Series C" },
    { key: "PreSerirsA", value: "Pre-Serirs A" },
    { key: "ExtendedSeriesB", value: "Extended Series B" },
    { key: "EDebtFinancing", value: "EDebt Financing" },
    { key: "Unknown", value: "Unknown" },
  ],
  Updateon: "Update on",
  recharge36: "Please install MetaMask",
  password3: "confirm",
  error: "operation failure",
  airdrop: {
    name: "Airdrop",
    Project: "Project",
    Participated: "Participated",
    NumberofWinners: "Number of Winners",
    TotalAirdropAmount: "Total Airdrop Amount",
    Endsin: "Ends in",
    OngoingAirdrops: "Ongoing Airdrops",
    UpcomingAirdrops: "Upcoming Airdrops",
    FinishedAirdrops: "Finished Airdrops",
    wicryptcom: "wicrypt.com",
    Community: "Community",
    Whitepaper: "Whitepaper",
    Overview: "Overview",
    Timeremaining: "Time remaining",
    Participate: "Participate",
    DAYS: "DAYS",
    HOURS: "HOURS",
    MINS: "MINS",
    Ongoing: "Ongoing",
    Upcoming: "Upcoming",
    SelfReportedTags: "Self Reported Tags",
    Participants: "Participants",
    Share: "Share",
    CheckOtherAirdrops: "Check Other Airdrops",
    participated: "You’ve already participated in this airdrop",
    participate: "How-to-Participate？",
    Starting: "Starting Job",
  },
  givealike: {
    title: "How do you feel about {project} today?",
    sontitle: "Vote to see community results",
  },
  market: "We are preparing the sale",
  cataloguelist: [
    { value: "ABOUT LAUNCHPAD", key: "aboutLaunchpad" },
    { value: "PROJECT OVERVIEW", key: "overview" },
    { value: "GO-TO-MARKET STRATEGY", key: "marketStrategy" },
    { value: "PRODUCT VIABILITY", key: "productViability" },
    { value: "PRODUCT ROADMAP", key: "productRoadmap" },
    { value: "REVENUE STREAMS", key: "revenueStreams" },
    { value: "TEAM", key: "team" },
    { value: "TOKEN ECONOMY", key: "tokenEconomy" },
    { value: "DETAILED TOKEN METRICS", key: "tokenMetricsDetailed" },
    { value: "PARTNERS", key: "partners" },
  ],
  blacklist: "Join the Whitelist",
  blacklisttitle:
    "We are excited to launch our IDO on Fomoin. For a chance to win a whitelist spot, please fill out the form below and perform all tasks accordingly. If you have any questions, please contact us!",
  essential: "Basic Information",
  fullname: "Full Name...",
  mailbox: "Email Address...",
  SocialMedia: "Social Media",
  SocialMediatitle:
    "Please complete the social tasks below. They’re optional, but provide increased chances of getting whitelisted. ",
  country: "Country...* ",
  Conditions: "Do you agree with the",
  Conditions2: "Terms and Conditions?",
  Readmore: "Read more",

  airdropForms: {
    desc1: "Add The Sandbox to Watchlist dada Watchlist",
    desc2: "Please add to watchlist first!",
    follow: "Follow",
    tips: "Please complete the above tasks, we'll only select winners among our followers.",
    WalletAddress: "Wallet Address",
    pleaseFill: "Please fill your",
    handle: "handle",
    WalletAddressPlaceholder: "Enter your Wallet Address...",
    AddressRequired: "Address is required",
    agreeError: "Please agree to our user agreement!",
    JoinAirdrop: "Join-Airdrop",
    agree1:
      "I understand and acknowledge that fulfilling the aforementioned conditions DOES NOT guarantee success as the winners will be determined solely by the airdrop partner according to their selection criteria.",
    agree2:
      "I will contact the airdrop partner directly if I have any queries about the results or distribution.",
    agree3:
      "I have reviewed these cybersecurity best practices and will take the necessary precautions to safeguard myself against malicious actors.",
  },
};
